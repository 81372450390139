<template>
  <hr class="w-100 o-25">
</template>
<script>
export default {
  name: 'IntroDivider'
}
</script>

<style scoped>
hr {
  margin: 30px 0;
}
</style>
