<template>
  <ib-dialog
    :visible="visible"
    :fullscreen="true"
    @close="close"
  >
    <ib-dialog-container max-width="940px">

      <!-- Header -->
      <intro-header
        :chapter="4"
        :image="require('@/assets/img/business-guide/icon-projections.svg')"
        :title="$t('dialogs.projections.projections')"
        :subtitle="$t('dialogs.projections.whenAllIsSet')"
        :description="$t('dialogs.projections.forecastYourPerformance')"
      />
      <!-- /Header -->

      <!-- Divider -->
      <intro-divider />
      <!-- /Divider -->

      <!-- Inner Wrapper -->
      <div class="inner-wrapper mb-5">

        <!-- Step 1 -->
        <el-row :gutter="60" class="d-flex flex-column flex-sm-row align-items-center py-4">
          <el-col :md="12" class="mb-4 mb-sm-0">
            <ib-pill text-uppercase>
              {{ $t('stepNumber', {number: 1}) }}
            </ib-pill>
            <intro-step-info
              :title="$t('dialogs.projections.revenueStreams.revenueStreams')"
              :description="$t('dialogs.projections.revenueStreams.defineYourRevenue')"
            >
              <ib-button-basic
                color="blue" class="my-4"
                @click="$router.push({name: 'business-guide-projections-revenue-streams'})"
              >
                {{ $t('startHere') }}
              </ib-button-basic>
            </intro-step-info>
          </el-col>
          <el-col :md="12">
            <div class="concept-steps-image-wrapper d-flex justify-content-center">
              <img src="@/assets/img/icons/tutorials/forecast/step1.png" alt="">
            </div>
          </el-col>
        </el-row>
        <!-- /Step 1 -->

        <!-- Divider -->
        <intro-divider />
        <!-- /Divider -->

        <!-- Step 2 -->
        <el-row :gutter="60" class="d-flex flex-column flex-sm-row-reverse align-items-center py-4">
          <el-col :md="12" class="mb-4 mb-sm-0">
            <ib-pill text-uppercase>
              {{ $t('stepNumber', {number: 2}) }}
            </ib-pill>
            <intro-step-info
              :title="$t('dialogs.projections.directCosts.directCosts')"
              :description="$t('dialogs.projections.directCosts.findOutWhatAreDirectCosts')"
            >
              <ib-button-basic
                color="blue" class="my-4"
                @click="$router.push({name: 'business-guide-projections-direct-costs'})"
              >
                {{ $t('startHere') }}
              </ib-button-basic>
            </intro-step-info>
          </el-col>
          <el-col :md="12">
            <div class="concept-steps-image-wrapper d-flex justify-content-center">
              <img src="@/assets/img/icons/tutorials/forecast/step2.png" alt="">
            </div>
          </el-col>
        </el-row>
        <!-- /Step 2 -->

        <!-- Divider -->
        <intro-divider />
        <!-- /Divider -->

        <!-- Step 3 -->
        <el-row :gutter="60" class="d-flex flex-column flex-sm-row align-items-center py-4">
          <el-col :md="12" class="mb-4 mb-sm-0">
            <ib-pill text-uppercase>
              {{ $t('stepNumber', {number: 3}) }}
            </ib-pill>
            <intro-step-info
              :title="$t('dialogs.projections.marketingBudget.marketingBudget')"
              :description="$t('dialogs.projections.marketingBudget.setTheMonthlyMarketingBudget')"
            >
              <ib-button-basic
                color="blue" class="my-4"
                @click="$router.push({name: 'business-guide-projections-marketing-budget'})"
              >
                {{ $t('startHere') }}
              </ib-button-basic>
            </intro-step-info>
          </el-col>
          <el-col :md="12">
            <div class="concept-steps-image-wrapper d-flex justify-content-center">
              <img src="@/assets/img/icons/tutorials/forecast/step3.png" alt="">
            </div>
          </el-col>
        </el-row>
        <!-- /Step 3 -->

        <!-- Divider -->
        <intro-divider />
        <!-- /Divider -->

        <!-- Step 4 -->
        <el-row :gutter="60" class="d-flex flex-column flex-sm-row-reverse align-items-center py-4">
          <el-col :md="12" class="mb-4 mb-sm-0">
            <ib-pill text-uppercase>
              {{ $t('stepNumber', {number: 4}) }}
            </ib-pill>
            <intro-step-info
              :title="$t('dialogs.projections.otherOverheads.otherOverheads')"
              :description="$t('dialogs.projections.otherOverheads.determineAllOtherMonthlyExpenses')"
            >
              <ib-button-basic
                color="blue" class="my-4"
                @click="$router.push({name: 'business-guide-projections-other-overheads'})"
              >
                {{ $t('startHere') }}
              </ib-button-basic>
            </intro-step-info>
          </el-col>
          <el-col :md="12">
            <div class="concept-steps-image-wrapper d-flex justify-content-center">
              <img src="@/assets/img/icons/tutorials/forecast/step4.png" alt="">
            </div>
          </el-col>
        </el-row>
        <!-- /Step 4 -->

        <!-- Divider -->
        <intro-divider />
        <!-- /Divider -->

        <!-- Step 5 -->
        <el-row :gutter="60" class="d-flex flex-column flex-sm-row align-items-center py-4">
          <el-col :md="12" class="mb-4 mb-sm-0">
            <ib-pill text-uppercase>
              {{ $t('stepNumber', {number: 5}) }}
            </ib-pill>
            <intro-step-info
              :title="$t('dialogs.projections.profitAndCashFlow.profitAndCashFlow')"
              :description="$t('dialogs.projections.profitAndCashFlow.whenAllThePreviousStepsAreCompleted')"
            >
              <ib-button-basic
                color="blue" class="my-4"
                @click="$router.push({name: 'business-guide-projections-profit-and-cash-flow'})"
              >
                {{ $t('startHere') }}
              </ib-button-basic>
            </intro-step-info>
          </el-col>
          <el-col :md="12">
            <div class="concept-steps-image-wrapper d-flex justify-content-center">
              <img src="@/assets/img/icons/tutorials/forecast/step5.png" alt="">
            </div>
          </el-col>
        </el-row>
        <!-- /Step 5 -->

        <!-- Divider -->
        <intro-divider />
        <!-- /Divider -->

        <!--        &lt;!&ndash; Step 6 &ndash;&gt;-->
        <!--        <el-row :gutter="60" class="o-50 d-flex flex-column flex-sm-row-reverse align-items-center">-->
        <!--          <el-col :md="12" class="mb-4 mb-sm-0">-->
        <!--            &lt;!&ndash;<ib-pill text-uppercase>{{ $t('stepNumber', {number: 6}) }}</ib-pill>&ndash;&gt;-->
        <!--            <ib-pill>-->
        <!--              {{ $t('dialogs.projections.industryStandards.comingSoon') }}-->
        <!--            </ib-pill>-->
        <!--            <intro-step-info-->
        <!--              :title="$t('dialogs.projections.industryStandards.industryStandards')"-->
        <!--              :description="$t('dialogs.projections.industryStandards.compareYourEstimatedProfit')"-->
        <!--            />-->
        <!--          </el-col>-->
        <!--          <el-col :md="12">-->
        <!--            <img class="mw-100" src="@/assets/img/icons/tutorials/forecast/step6.png" alt="">-->
        <!--          </el-col>-->
        <!--        </el-row>-->
        <!--        &lt;!&ndash; /Step 6 &ndash;&gt;-->

        <!--        &lt;!&ndash; Divider &ndash;&gt;-->
        <!--        <intro-divider />-->
        <!--        &lt;!&ndash; /Divider &ndash;&gt;-->

        <!-- Dialog Bottom -->
        <intro-footer
          :btn-text="$t('dialogs.projections.startWithProjectionsSection')"
          @click="onStart"
        />
        <!-- /Dialog Bottom -->

      </div>
      <!-- /Inner Wrapper -->

    </ib-dialog-container>
  </ib-dialog>
</template>

<script>
import IntroDivider from './Components/IntroDivider'
import IntroFooter from './Components/IntroFooter'
import IntroHeader from './Components/IntroHeader'
import IntroStepInfo from './Components/IntroStepInfo'

export default {
  name: 'IntroProjections',

  components: {
    IntroDivider,
    IntroHeader,
    IntroFooter,
    IntroStepInfo
  },

  props: {
    visible: Boolean
  },

  methods: {
    close () {
      this.$emit('close')
    },

    onStart () {
      this.$router.push({ name: 'business-guide-projections-revenue-streams' })

      this.$gtm.trackEvent({
        event: 'projectionsCTA'
      })
    }
  }
}
</script>

<style scoped lang="scss">
.concept-steps-image-wrapper {
  img {
    max-width: 80%;
  }
}

</style>
