<template>
  <ib-dialog
    :visible="visible"
    :fullscreen="true"
    @close="close"
  >
    <ib-dialog-container max-width="940px">

      <!-- Header -->
      <intro-header
        :chapter="3"
        :image="require('@/assets/img/business-guide/icon-setup.svg')"
        :title="$t('dialogs.setup.setup')"
        :subtitle="$t('dialogs.setup.ifYouHaveAlreadyCompleted')"
        :description="$t('dialogs.setup.setupChapterWillHelpYou')"
      />
      <!-- /Header -->

      <!-- Divider -->
      <intro-divider />
      <!-- /Divider -->

      <!-- Inner Wrapper -->
      <div class="inner-wrapper mb-5">
        <!-- Step 1 -->
        <el-row :gutter="60" class="d-flex flex-column flex-sm-row align-items-center py-4">
          <el-col :md="12" class="mb-4 mb-sm-0">
            <div class="text-content">
              <ib-pill text-uppercase>
                {{ $t('stepNumber', {number: 1}) }}
              </ib-pill>
              <h3>{{ $t('dialogs.setup.startupCosts.startupCosts') }}</h3>
              <p>{{ $t('dialogs.setup.startupCosts.calculateTheTotalWorth') }}</p>
            </div>
            <ib-button-basic
              color="blue" class="my-4"
              @click="$router.push({name: 'business-guide-set-up-startup-costs'})"
            >
              {{ $t('startHere') }}
            </ib-button-basic>
          </el-col>
          <el-col :md="12">
            <div class="mockup-content concept-steps-image-wrapper d-flex justify-content-center">
              <img src="@/assets/img/icons/tutorials/set-up/step1.png" alt="">
            </div>
          </el-col>
        </el-row>
        <!-- /Step 1 -->

        <!-- Divider -->
        <intro-divider />
        <!-- /Divider -->

        <!-- Step 2 -->
        <el-row :gutter="60" class="d-flex flex-column flex-sm-row-reverse align-items-center py-4">
          <el-col :md="12" class="mb-4 mb-sm-0">
            <div class="text-content">
              <ib-pill text-uppercase>
                {{ $t('stepNumber', {number: 2}) }}
              </ib-pill>
              <h3>{{ $t('dialogs.setup.financing.financing') }}</h3>
              <p>{{ $t('dialogs.setup.financing.exploreAllTheMeans') }}</p>
            </div>
            <ib-button-basic
              color="blue" class="my-4"
              @click="$router.push({name: 'business-guide-set-up-financing'})"
            >
              {{ $t('startHere') }}
            </ib-button-basic>
          </el-col>
          <el-col :md="12">
            <div class="mockup-content concept-steps-image-wrapper d-flex justify-content-center">
              <img src="@/assets/img/icons/tutorials/set-up/step2.png" alt="">
            </div>
          </el-col>
        </el-row>
        <!-- /Step 2 -->

        <!-- Divider -->
        <intro-divider />
        <!-- /Divider -->

        <!-- Step 4 -->
        <el-row :gutter="60" class="d-flex flex-column flex-sm-row align-items-center py-4">
          <el-col :md="12" class="mb-4 mb-sm-0">
            <div class="text-content">
              <ib-pill text-uppercase>
                {{ $t('stepNumber', {number: 3}) }}
              </ib-pill>
              <h3>{{ $t('dialogs.setup.managementTeam.managementTeam') }}</h3>
              <p>{{ $t('dialogs.setup.managementTeam.describeTheLeaders') }}</p>
            </div>
            <ib-button-basic
              color="blue" class="my-4"
              @click="$router.push({name: 'business-guide-set-up-management-team'})"
            >
              {{ $t('startHere') }}
            </ib-button-basic>
          </el-col>
          <el-col :md="12">
            <div class="mockup-content concept-steps-image-wrapper d-flex justify-content-center">
              <img src="@/assets/img/icons/tutorials/set-up/step4.png" alt="">
            </div>
          </el-col>
        </el-row>
        <!-- /Step 4 -->

        <!-- Divider -->
        <intro-divider />
        <!-- /Divider -->

        <!-- Step 5 -->
        <el-row :gutter="60" class="d-flex flex-column flex-sm-row-reverse align-items-center py-4">
          <el-col :md="12" class="mb-4 mb-sm-0">
            <div class="text-content">
              <ib-pill text-uppercase>
                {{ $t('stepNumber', {number: 4}) }}
              </ib-pill>
              <h3>{{ $t('dialogs.setup.staffAndPayroll.staffAndPayroll') }}</h3>
              <p>{{ $t('dialogs.setup.staffAndPayroll.identifyStaffingNeeds') }}</p>
            </div>
            <ib-button-basic
              color="blue" class="my-4"
              @click="$router.push({name: 'business-guide-set-up-staff-and-payroll'})"
            >
              {{ $t('startHere') }}
            </ib-button-basic>
          </el-col>
          <el-col :md="12">
            <div class="mockup-content concept-steps-image-wrapper d-flex justify-content-center">
              <img src="@/assets/img/icons/tutorials/set-up/step5.png" alt="">
            </div>
          </el-col>
        </el-row>
        <!-- /Step 5 -->

        <!-- Divider -->
        <intro-divider />
        <!-- /Divider -->

        <!-- Step 3 -->
        <el-row :gutter="60" class="d-flex flex-column flex-sm-row align-items-center py-4">
          <el-col :md="12" class="mb-4 mb-sm-0">
            <div class="text-content">
              <ib-pill text-uppercase>
                {{ $t('stepNumber', {number: 5}) }}
              </ib-pill>
              <h3>{{ $t('dialogs.setup.ownership.ownership') }}</h3>
              <p>{{ $t('dialogs.setup.ownership.determineTheOwnership') }}</p>
            </div>
            <ib-button-basic
              color="blue" class="my-4"
              @click="$router.push({name: 'business-guide-set-up-ownership'})"
            >
              {{ $t('startHere') }}
            </ib-button-basic>
          </el-col>
          <el-col :md="12">
            <div class="mockup-content concept-steps-image-wrapper d-flex justify-content-center">
              <img src="@/assets/img/icons/tutorials/set-up/step3.png" alt="">
            </div>
          </el-col>
        </el-row>
        <!-- /Step 3 -->

        <!-- Divider -->
        <intro-divider />
        <!-- /Divider -->

        <!--        &lt;!&ndash; Step 6 &ndash;&gt;-->
        <!--        <el-row :gutter="60" class="o-50 d-flex flex-column flex-sm-row-reverse align-items-center">-->
        <!--          <el-col :md="12" class="mb-4 mb-sm-0">-->
        <!--            <div class="text-content">-->
        <!--              <ib-pill text-uppercase>-->
        <!--                {{ $t('dialogs.setup.checklist.comingSoon') }}-->
        <!--              </ib-pill>-->
        <!--              <h3>{{ $t('dialogs.setup.checklist.checklist') }}</h3>-->
        <!--              <p>{{ $t('dialogs.setup.checklist.thisSectionWillHelpYou') }}</p>-->
        <!--            </div>-->
        <!--          </el-col>-->
        <!--          <el-col :md="12">-->
        <!--            <div class="mockup-content">-->
        <!--              <img class="mw-100" src="@/assets/img/icons/tutorials/set-up/step6.png" alt="">-->
        <!--            </div>-->
        <!--          </el-col>-->
        <!--        </el-row>-->
        <!--        &lt;!&ndash; /Step 6 &ndash;&gt;-->

        <!--        &lt;!&ndash; Divider &ndash;&gt;-->
        <!--        <intro-divider />-->
        <!--        &lt;!&ndash; /Divider &ndash;&gt;-->

        <!-- Dialog Bottom -->
        <intro-footer
          :btn-text="$t('dialogs.setup.startWithSetupSection')"
          @click="onStart"
        />
        <!-- /Dialog Bottom -->

      </div>
      <!-- /Inner Wrapper -->

    </ib-dialog-container>
  </ib-dialog>
</template>

<script>
import IntroDivider from './Components/IntroDivider'
import IntroFooter from './Components/IntroFooter'
import IntroHeader from './Components/IntroHeader'

export default {
  name: 'IntroSetup',

  components: {
    IntroDivider,
    IntroHeader,
    IntroFooter
  },

  props: {
    visible: Boolean
  },

  methods: {
    close () {
      this.$emit('close')
    },

    onStart () {
      this.$router.push({ name: 'business-guide-set-up-startup-costs' })

      this.$gtm.trackEvent({
        event: 'setupCTA'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.text-content {
  h3 {
    margin: 10px 0;
  }

  p, ul {
    font-size: 16px;
    line-height: 1.5;
  }
}

.concept-steps-image-wrapper {
  img {
    max-width: 80%;
  }
}
</style>
