<template>
  <ib-dialog
    :visible="visible"
    :fullscreen="true"
    @close="close"
  >
    <ib-dialog-container max-width="940px">

      <!-- Header -->
      <intro-header
        :chapter="2"
        :image="require('@/assets/img/business-guide/icon-research.svg')"
        :title="$t('dialogs.research.research')"
        :subtitle="$t('dialogs.research.howMuchDoYouKnow')"
        :description="$t('dialogs.research.thisChapterWillHelpYou')"
      />
      <!-- /Header -->

      <!-- Divider -->
      <intro-divider />
      <!-- /Divider -->

      <!-- Inner Wrapper -->
      <div class="inner-wrapper mb-5">
        <!-- Step 1 -->
        <el-row :gutter="60" class="d-flex flex-column flex-sm-row align-items-center py-4">
          <el-col :md="12" class="mb-4 mb-sm-0">
            <ib-pill text-uppercase>
              {{ $t('stepNumber', {number: 1}) }}
            </ib-pill>
            <intro-step-info
              :title="$t('dialogs.research.market.market')"
              :description="$t('dialogs.research.market.describeTheMarket')"
            >
              <ib-button-basic
                color="blue" class="my-4"
                @click="$router.push({name: 'business-guide-research-market'})"
              >
                {{ $t('startHere') }}
              </ib-button-basic>
            </intro-step-info>
          </el-col>
          <el-col :md="12">
            <div class="concept-steps-image-wrapper d-flex justify-content-center">
              <img src="@/assets/img/icons/tutorials/research/step1.png" alt="">
            </div>
          </el-col>
        </el-row>
        <!-- /Step 1 -->

        <!-- Divider -->
        <intro-divider />
        <!-- /Divider -->

        <!-- Step 2 -->
        <el-row :gutter="60" class="d-flex flex-column flex-sm-row-reverse align-items-center py-4">
          <el-col :md="12" class="mb-4 mb-sm-0">
            <ib-pill text-uppercase>
              {{ $t('stepNumber', {number: 2}) }}
            </ib-pill>
            <intro-step-info
              :title="$t('dialogs.research.customers.customers')"
              :description="$t('dialogs.research.customers.createTheProfiles')"
            >
              <ib-button-basic
                color="blue" class="my-4"
                @click="$router.push({name: 'business-guide-research-customers'})"
              >
                {{ $t('startHere') }}
              </ib-button-basic>
            </intro-step-info>
          </el-col>
          <el-col :md="12">
            <div class="concept-steps-image-wrapper d-flex justify-content-center">
              <img src="@/assets/img/icons/tutorials/research/step2.png" alt="">
            </div>
          </el-col>
        </el-row>
        <!-- /Step 2 -->

        <!-- Divider -->
        <intro-divider />
        <!-- /Divider -->

        <!-- Step 3 -->
        <el-row :gutter="60" class="d-flex flex-column flex-sm-row align-items-center py-4">
          <el-col :md="12" class="mb-4 mb-sm-0">
            <ib-pill text-uppercase>
              {{ $t('stepNumber', {number: 3}) }}
            </ib-pill>
            <intro-step-info
              :title="$t('dialogs.research.competitors.competitors')"
              :description="$t('dialogs.research.competitors.studyUpOnTHeCompetition')"
            >
              <ib-button-basic
                color="blue" class="my-4"
                @click="$router.push({name: 'business-guide-research-competitors'})"
              >
                {{ $t('startHere') }}
              </ib-button-basic>
            </intro-step-info>
          </el-col>
          <el-col :md="12">
            <div class="concept-steps-image-wrapper d-flex justify-content-center">
              <img src="@/assets/img/icons/tutorials/research/step3.png" alt="">
            </div>
          </el-col>
        </el-row>
        <!-- /Step 3 -->

        <!-- Divider -->
        <intro-divider />
        <!-- /Divider -->

        <!--        &lt;!&ndash; Step 4 &ndash;&gt;-->
        <!--        <el-row :gutter="60" class="o-50 d-flex flex-column flex-sm-row-reverse align-items-center">-->
        <!--          <el-col :md="12" class="mb-4 mb-sm-0">-->
        <!--            <ib-pill text-uppercase>-->
        <!--              {{ $t('dialogs.research.step4.comingSoon') }}-->
        <!--            </ib-pill>-->
        <!--            <intro-step-info-->
        <!--              :title="$t('dialogs.research.comparison.comparison')"-->
        <!--              :description="$t('dialogs.research.comparison.compareYourBusinessIdea')"-->
        <!--            />-->
        <!--          </el-col>-->
        <!--          <el-col :md="12">-->
        <!--            <img class="mw-100" src="@/assets/img/icons/tutorials/research/step4.png" alt="">-->
        <!--          </el-col>-->
        <!--        </el-row>-->
        <!--        &lt;!&ndash; /Step 4 &ndash;&gt;-->

        <!--        &lt;!&ndash; Divider &ndash;&gt;-->
        <!--        <intro-divider />-->
        <!--        &lt;!&ndash; /Divider &ndash;&gt;-->

        <!-- Step 5 -->
        <el-row :gutter="60" class="d-flex flex-column flex-sm-row-reverse align-items-center py-4">
          <el-col :md="12" class="mb-4 mb-sm-0">
            <ib-pill text-uppercase>
              {{ $t('stepNumber', {number: 4}) }}
            </ib-pill>
            <intro-step-info
              :title="$t('dialogs.research.swotAnalysis.swotAnalysis')"
              :description="$t('dialogs.research.swotAnalysis.swotAnalysisWillHelpYou')"
            >
              <ib-button-basic
                color="blue" class="my-4"
                @click="$router.push({name: 'business-guide-research-swot-analysis'})"
              >
                {{ $t('startHere') }}
              </ib-button-basic>
            </intro-step-info>
          </el-col>
          <el-col :md="12">
            <div class="concept-steps-image-wrapper d-flex justify-content-center">
              <img src="@/assets/img/icons/tutorials/research/step5.png" alt="">
            </div>
          </el-col>
        </el-row>
        <!-- /Step 5 -->

        <!-- Divider -->
        <intro-divider />
        <!-- /Divider -->

        <!-- Step 6 -->
        <el-row :gutter="60" class="d-flex flex-column flex-sm-row align-items-center py-4">
          <el-col :md="12" class="mb-4 mb-sm-0">
            <ib-pill text-uppercase>
              {{ $t('stepNumber', {number: 5}) }}
            </ib-pill>
            <intro-step-info
              :title="$t('dialogs.research.marketPotential.marketPotential')"
              :description="$t('dialogs.research.marketPotential.calculateTheUpperLimits')"
            >
              <ib-button-basic
                color="blue" class="my-4"
                @click="$router.push({name: 'business-guide-research-market-potential'})"
              >
                {{ $t('startHere') }}
              </ib-button-basic>
            </intro-step-info>
          </el-col>
          <el-col :md="12">
            <div class="concept-steps-image-wrapper d-flex justify-content-center">
              <img style="width: 100%" src="@/assets/img/icons/tutorials/research/step6.png" alt="">
            </div>
          </el-col>
        </el-row>
        <!-- /Step 6 -->

        <!-- Divider -->
        <intro-divider />
        <!-- /Divider -->

        <!-- Dialog Bottom -->
        <intro-footer
          :btn-text="$t('dialogs.research.startWithResearchSection')"
          @click="onStart"
        />
        <!-- /Dialog Bottom -->

      </div>
      <!-- /Inner Wrapper -->

    </ib-dialog-container>
  </ib-dialog>
</template>

<script>
import IntroDivider from './Components/IntroDivider'
import IntroFooter from './Components/IntroFooter'
import IntroHeader from './Components/IntroHeader'
import IntroStepInfo from './Components/IntroStepInfo'

export default {
  name: 'IntroResearch',

  components: {
    IntroDivider,
    IntroHeader,
    IntroFooter,
    IntroStepInfo
  },

  props: {
    visible: Boolean
  },

  methods: {
    close () {
      this.$emit('close')
    },

    onStart () {
      this.$router.push({ name: 'business-guide-research-market' })

      this.$gtm.trackEvent({
        event: 'researchCTA'
      })
    }
  }
}
</script>

<style scoped lang="scss">
.concept-steps-image-wrapper {
  img {
    max-width: 80%;
  }
}

</style>
